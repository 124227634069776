<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <v-card-text class="text-h5 pa-2">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              :disabled="accessRight.length < 2 && accessRight.includes('show')"
            >
              <Alert :alert.sync="alert" :message="message" />
              <v-row>
                <v-col cols="12">
                  <v-card class="mx-auto" flat>
                    <v-card-title class="py-1 primary--text">
                      <v-icon color="primary">mdi-card-multiple-outline</v-icon>
                      <h6 class="ms-2 font-weight-regular">{{ $t("route.createFromMultiple") }}</h6>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-row :dense="true">
                            <v-col cols="5">
                              <v-combobox
                                ref="supplier"
                                class="asterisk"
                                autocomplete="new-password"
                                dense
                                cache-items
                                v-model="selectedSupplier"
                                :items="supplierList.data"
                                item-text="name_1"
                                item-value="id"
                                flat
                                :rules="supplierValid"
                                @change="onSupplierChange"
                                return-object
                                hide-no-data
                                hide-details="auto"
                                required
                                single-line
                                @dblclick="redirectOnEditSupplierPage"
                                :search-input.sync="supplierSearch"
                                :label="$t('supplier.supplier')"
                              >
                                <template v-slot:append-outer>
                                  <v-icon color="primary" dense @click="supplierDialog = true">mdi-magnify</v-icon>
                                </template>
                                <template v-slot:selection="data">
                                  <span @dblclick="redirectOnEditSupplierPage">
                                    {{`${data.item.id}. ${data.item.name_1}`}}
                                  </span>
                                </template>
                                <template v-slot:item="data">
                                  <div class="font-weight-medium" style="font-size: 0.8125rem;display: flex;align-item: center;">
                                    {{ `${data.item.id}. ${data.item.name_1}` }}
                                  </div>
                                </template>
                                <template v-slot:append-item>
                                  <div v-show="hasSupplierNextPage" v-intersect="infiniteScroll" ref="load" class="loader text-center">
                                    <v-progress-circular indeterminate color="primary"/>
                                  </div>
                                </template>
                              </v-combobox>
                              <v-autocomplete
                                hide-details="auto"
                                class="my-8 asterisk"
                                height="30%"
                                dense
                                v-model="selectedGroup"
                                :items="groupValue"
                                :rules="documentTypeValid"
                                item-text="name"
                                item-value="id"
                                clearable
                                flat
                                return-object
                                single-line
                                @change="groupValueChange"
                                :label="$t('route.createNewDocument')"
                              />
                            </v-col>
                            <v-col cols="1"></v-col>
                            <v-col cols="6">
                              <v-row>
                                <v-col cols="3">
                                  <v-checkbox
                                    :dense="true"
                                    v-model="model.purchaseOrders"
                                    :disabled="!disableDocs.purchaseOrders"
                                    :label="$t('route.purchaseOrder')"
                                  />
                                </v-col>
                                <v-col cols="3">
                                  <v-checkbox
                                    :dense="true"
                                    v-model="model.supplierDelivery"
                                    :disabled="!disableDocs.supplierDelivery"
                                    :label="$t('route.supplierDeliveryNote')"
                                  />
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="5">
                                  <WarehouseInput :selectedWarehouseModel.sync="selectedWarehouse" />
                                </v-col>
                                <v-col cols="2"></v-col>
                                <v-col cols="5" v-if="selectedGroup && selectedGroup.id === 3">
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="model.invoice_no"
                                    :label="$t('supplierInvoice.invoiceNo')"
                                    :rules="invoiceNoRequired"
                                    required
                                  />
                                </v-col>
                                <v-col cols="5" v-if="selectedGroup && selectedGroup.id === 2">
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="model.delivery_note_no"
                                    :label="$t('supplierDeliveryNote.deliveryNoteNo')"
                                    :rules="deliveryNoteNoRequired"
                                    required
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-row :dense="true">
                            <v-col cols="3">
                              <v-menu
                                ref="startDateMenu"
                                v-model="startDateMenu"
                                :close-on-content-click="false"
                                :return-value.sync="quotationDateFormat"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="model.create_date"
                                    :label="$t('invoice.createDate')"
                                    v-bind="attrs"
                                    v-on="on"
                                    readonly
                                  />
                                </template>
                                <v-date-picker
                                  v-model="quotationDateFormat"
                                  @change="$refs.startDateMenu.save(quotationDateFormat)"
                                >
                                  <v-btn small class="primary" @click="$refs.startDateMenu.save(todayDate())">{{ $t("route.today") }}</v-btn>
                                </v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col cols="3">
                              <v-checkbox :dense="true" v-model="model.vatNew" :label="$t('common.documentVAT')"/>
                            </v-col>
                            <v-col cols="3">
                              <v-menu
                                ref="startDateMenu2"
                                v-model="startDateMenu2"
                                :close-on-content-click="false"
                                :return-value.sync="order_date"
                                transition="scale-transition"
                                offset-y
                                :nudge-left="40"
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    hide-details="auto"
                                    class="me-2"
                                    v-model="computedDateFormatted2"
                                    :label="$t('invoice.from')"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                  />
                                </template>
                                <v-date-picker
                                  v-model="order_date"
                                  @change="$refs.startDateMenu2.save(order_date)"
                                >
                                  <v-btn small class="primary" @click="$refs.startDateMenu2.save(todayDate())">{{ $t("route.today") }}</v-btn>
                                </v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col cols="3">
                              <v-menu
                                ref="endDateMenu"
                                v-model="endDateMenu"
                                :close-on-content-click="false"
                                :return-value.sync="order_validity"
                                transition="scale-transition"
                                offset-y
                                :nudge-left="160"
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="computedorderValideDateFormat"
                                    :label="$t('invoice.to')"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                  />
                                </template>
                                <v-date-picker
                                  :min="order_date ? order_date : ''"
                                  v-model="order_validity"
                                  @change="$refs.endDateMenu.save(order_validity)"
                                >
                                  <v-btn small class="primary" @click="$refs.endDateMenu.save(todayDate())">{{ $t("route.today") }}</v-btn>
                                </v-date-picker>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" class="text-center mt-3">
                          <v-btn
                            small
                            class="me-2"
                            :disabled="!valid"
                            @click="execute"
                            color="primary"
                          >
                            <v-icon left> mdi-filter-check-outline </v-icon>
                            {{ $t("salesReport.execute") }}
                          </v-btn>
                          <v-btn
                            color="primary"
                            small
                            :loading="loading"
                            :disabled="!valid || selectedItems.length <= 0 || (accessRight.length < 2 && accessRight.includes('show'))"
                            @click="saveMultiple"
                          >
                            {{ $t("route.createNewDocument") }}
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" class="text-center mt-3">
                          <v-simple-table
                            dense
                            class="custom-table elevation-1 item-table"
                            fixed-header
                            height="380"
                          >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th :style="{ width: '20px' }" class="text-center">
                                    <v-checkbox x-small dense hide-details @change="selectAllItem" v-model="selectAll"/>
                                  </th>
                                  <th :style="{ width: '130px' }" class="text-start">
                                    {{ $t("customer.documentType") }}
                                  </th>
                                  <th :style="{ width: '130px' }" class="text-start">
                                    {{ $t("customer.documentNo") }}
                                  </th>
                                  <th :style="{ width: '180px' }" class="text-start">
                                    {{ $t("delivery.product") }}
                                  </th>
                                  <th :style="{ width: '180px' }" class="text-start">
                                    {{ $t("delivery.itemDescription") }}
                                  </th>
                                  <th :style="{ width: '50px' }" class="text-start">
                                    {{ $t("delivery.quantity") }}
                                  </th>
                                  <th :style="{ width: '50px' }" class="text-start">
                                    {{ $t("route.supplied") }}
                                  </th>
                                  <th :style="{ width: '100px' }" class="text-start">
                                    {{ $t("delivery.unitPrice") }}
                                  </th>
                                  <th :style="{ width: '50px' }" class="text-start">
                                    {{ $t("delivery.lineDiscount") }}
                                  </th>
                                  <th :style="{ width: '110px' }" class="text-start">
                                    {{ $t("delivery.total") }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-if="processedData.length <= 0">
                                  <td colspan="10" class="text-center">{{ $t("common.noDataFound") }}</td>
                                </tr>
                                <tr
                                  v-for="(item, index) in processedData"
                                  style="vertical-align: bottom"
                                  :key="index"
                                  :class="index + 1 == multipleData.length ? 'newRow' : ''"
                                  :style="item.typeRow ? 'background-color: #E0E0E0' : item.type === 'discount' ? 'background-color: yellow' : ''"
                                >
                                  <td v-if="item.typeRow">
                                    <v-checkbox x-small dense hide-details @click="toggleTypeSelection(item,typeSelection[item.documentId])" v-model="typeSelection[item.documentId]" />
                                  </td>
                                  <td v-if="item.typeRow" class="text-start font-weight-bold">
                                    <span>
                                      {{ documentTypeInclude.includes(item.documentType) && item.documentType ? $t(`route.${item.documentType}`) : "" }}
                                    </span>
                                  </td>
                                  <td v-if="item.typeRow" :colspan="2" class="text-center font-weight-bold">
                                    <span>
                                      {{ item.documentNumber ? item.documentNumber : ""}}
                                    </span>
                                  </td>
                                  <td v-if="item.typeRow" :colspan="2" class="text-center font-weight-bold">
                                    <span>{{ item.title ? item.title : "" }}</span>
                                  </td>
                                  <td v-if="item.typeRow" :colspan="2" class="text-center font-weight-bold">
                                    <span>{{ item.customerOrderNo ? item.customerOrderNo : "" }}</span>
                                  </td>
                                  <td v-if="item.typeRow" :colspan="2" class="text-center font-weight-bold">
                                    <span>
                                      {{ item.documentDate ? profileById.date_format ? formatDateDDMMYYYY(item.documentDate.split("T")[0]) : parseDateYYYYMMDD(item.documentDate.split("T")[0]) : "" }}
                                    </span>
                                  </td>
                                  <td v-if="!item.typeRow">
                                    <span v-if="item.type !== 'discount'">
                                      <v-checkbox x-small dense hide-details @click="selectedProduct(item, item.selected)" v-model="item.selected"/>
                                    </span>
                                  </td>
                                  <td class="text-start" v-if="!item.typeRow">
                                    <span>{{documentTypeInclude.includes(item.type) && item.type ? $t(`route.${item.type}`) : ""}}</span>
                                  </td>
                                  <td class="text-start" v-if="!item.typeRow" @dblclick="redirectDocument(item)">
                                    <span v-if="item.type !== 'discount'">{{ item.document_number }}</span>
                                  </td>
                                  <td class="text-start" v-if="!item.typeRow">
                                    <span>{{ item.item_code }}</span>
                                  </td>
                                  <td class="text-start" v-if="!item.typeRow">
                                    <span :class="item.type !== 'discount' ? '' : 'font-weight-bold'">
                                      {{ item.item_description }}
                                    </span>
                                  </td>
                                  <td class="text-start" v-if="!item.typeRow">
                                    <span :class="item.type !== 'discount' ? '' : 'font-weight-bold'">
                                      {{ item.quantity }}
                                    </span>
                                  </td>
                                  <td class="text-start" v-if="!item.typeRow">
                                    <v-text-field
                                      v-if="item.type !== 'discount'"
                                      dense
                                      hide-details="auto"
                                      required
                                      :rules="[(v) => !item.selected || (v && item.quantity >= v && v > 0) || $t('formRules.suppliedQtyRequired')]"
                                      v-model="item.supplied"
                                      @input="itemPriceCalculation(index)"
                                    />
                                  </td>
                                  <td class="text-start" v-if="!item.typeRow">
                                    <v-text-field
                                      v-if="item.type !== 'discount'"
                                      dense
                                      :name="'unit_price' + index"
                                      :ref="'unit_price' + index"
                                      hide-details="auto"
                                      v-model="item.unit_price"
                                      :rules="unitPriceValid"
                                      required
                                      readonly
                                      @input="itemPriceCalculation(index)"
                                    >
                                      <template v-if="item.unit_price === 0">
                                        {{ (item.unit_price = null) }}
                                      </template>
                                    </v-text-field>
                                    <span v-else class="font-weight-bold">
                                      {{ item.unit_price ? item.unit_price.toFixed(2) : 0.0 }}
                                    </span>
                                  </td>
                                  <td class="text-start" v-if="!item.typeRow">
                                    <v-text-field
                                      v-if="item.type !== 'discount'"
                                      dense
                                      hide-details="auto"
                                      v-model="item.line_discount"
                                      :rules="lineDiscountValid"
                                      required
                                      readonly
                                      @input="itemPriceCalculation(index)"
                                    >
                                      <template v-slot:append>
                                        <v-icon class="mt-2" x-small color="primary">mdi-percent-outline</v-icon>
                                      </template>
                                      <template v-if="item.line_discount === 0">
                                        {{ (item.line_discount = null) }}
                                      </template>
                                    </v-text-field>
                                  </td>
                                  <td class="text-start" v-if="!item.typeRow">
                                    <v-text-field
                                      v-if="item.type !== 'discount'"
                                      :name="'total_price' + index"
                                      :ref="'total_price' + index"
                                      dense
                                      hide-details="auto"
                                      v-model="item.total"
                                      :rules="unitPriceValid"
                                      required
                                      @input="itemPriceCalculation(index)"
                                    >
                                      <template v-if="item.total === 0">{{ (item.total = null) }}</template>
                                    </v-text-field>
                                    <span v-else class="font-weight-bold">
                                      {{item.total ? item.total.toFixed(2) : 0.0}}
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                          <v-overlay :z-index="999" v-if="circiular" :value="circiular">
                            <v-icon size="64" class="hourglass-icon" color="primary">mdi-timer-sand-paused</v-icon>
                          </v-overlay>
                        </v-col>
                      </v-row>
                      <v-row :dense="true" class="pt-6">
                        <v-col cols="8"></v-col>
                        <v-col cols="4" v-if="accessRight.includes('financial')">
                          <v-row dense>
                            <v-col cols="6" class="font-weight-bold">{{ $t("quotation.total") }}</v-col>
                            <v-col cols="6" class="text-start font-weight-bold">{{ numberWithCommas(model.total) }}</v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6">
                              <v-row dense no-gutters>
                                <v-col cols="6">{{ $t("quotation.field.discount") }}</v-col>
                                <v-col cols="6">
                                  <v-text-field
                                    height="18"
                                    class="ma-0 pa-0"
                                    hide-details="auto"
                                    dense
                                    :rules="discountValid"
                                    @input="priceCalculation"
                                    v-model="model.discount_percentage"
                                  >
                                    <template v-slot:append>
                                      <v-icon x-small color="primary">mdi-percent-outline</v-icon>
                                    </template>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="6" class="text-start">{{ numberWithCommas(model.discount) }}</v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6" class="font-weight-bold">{{ $t("quotation.field.afterDiscount") }}</v-col>
                            <v-col cols="6" class="text-start font-weight-bold">{{ numberWithCommas(model.after_discount) }}</v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6">
                              <v-row dense no-gutters>
                                <v-col cols="6">{{ $t("quotation.field.vat") }}</v-col>
                                <v-col cols="6">
                                  <v-text-field
                                    :disabled="includeVatCharge ? false : true"
                                    height="18"
                                    class="ma-0 pa-0"
                                    hide-details="auto"
                                    dense
                                    :rules="vatPrecentageValid"
                                    @input="priceCalculation"
                                    v-model="model.vat_percentage"
                                  >
                                    <template v-slot:append>
                                      <v-icon x-small color="primary">mdi-percent-outline</v-icon>
                                    </template>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="6" class="text-start">{{ numberWithCommas(model.vat) }}</v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6" class="font-weight-bold">{{ $t("quotation.field.grandTotal") }}</v-col>
                            <v-col cols="6" class="text-start font-weight-bold">{{ numberWithCommas(model.grand_total) }}</v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <DeliveryNoteNoConfirmationDialog v-if="selectedGroup" :dialog.sync="deliveryNoteNoDialog" :type="selectedGroup.slug"/>
      <SupplierDialog @onSupplierChange="onSupplierChange" :dialog.sync="supplierDialog"/>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import SupplierDialog from "@/components/moduleTableDialog/Dialog/SupplierDialog";
import Alert from "@/components/Alert";
import { formatDateDDMMYYYY, parseDateYYYYMMDD, todayDate } from "@/utils";
import DeliveryNoteNoConfirmationDialog from "@/components/DeliveryNoteNoConfirmationDialog";
import WarehouseInput from '@/components/WarehouseInput';
import { validationMixin } from '@/mixins/validationMixin';
import { intersectionMixin } from '@/mixins/intersectionMixin';
import { searchMixin } from '@/mixins/searchMixin';

export default {
  name: "PurchaseMerging",
  components: {
    SupplierDialog,
    Alert,
    DeliveryNoteNoConfirmationDialog,
    WarehouseInput
  },
  mixins: [validationMixin, intersectionMixin, searchMixin],
  data() {
    return {
      valid: false,
      selectedSupplier: null,
      supplierDialog: false,
      supplierLimit: 10,
      model: {
        purchaseOrders: false,
        supplierDelivery: false,
        vat_percentage: null,
        vatNew: false,
      },
      disableDocs: {
        purchaseOrders: false,
        supplierDelivery: false,
      },
      selectedGroup: null,
      multipleData: [],
      payloadData: [],
      supplied: null,
      selectAll: false,
      selectedItems: [],
      supplierData: {},
      circiular: false,
      message: "",
      alert: false,
      documentTypeInclude: [
       "purchaseOrder",
       "supplierDeliveryNote"
      ],
      startDateMenu: false,
      startDateMenu2: false,
      endDateMenu: false,
      quotationDateFormat: "",
      order_date: this.$route.query.startDate || new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().substring(0, 10),
      order_validity: this.$route.query.endDate || new Date().toISOString().substring(0, 10),
      typeSelection: {},
      loading: false,
      includeVatCharge: false,
      selectedWarehouse: null,
      deliveryNoteNoDialog: false,
    };
  },
  computed: {
    processedData() {
      const data = [];
      const typeSet = new Set();
      this.multipleData.forEach((item) => {
        if (!typeSet.has(item.document_id)) {
          typeSet.add(item.document_id);
          data.push({
            typeRow: true,
            documentType: item.type,
            selected: false,
            documentId: item.document_id,
            documentNumber: item.document_number,
            title: item.title,
            customerOrderNo: item.customer_order_no,
            documentDate: item.document_date,
          });
        }
        data.push(item);
      });
      return data;
    },
    ...mapGetters({
      accessRight: "accessRight",
      supplierList: "supplier/supplierList",
      profileById: "profile/profileById",
      locale: "locale",
    }),
    documentTypeValid() {
      return [(v) => !!v || this.$t("formRules.documentTypeRequired")];
    },
    suppliedQtyRequired() {
      return [(v) => !!v || this.$t("formRules.suppliedQtyRequired")];
    },
    computedDateFormatted() {
      return this.profileById.date_format ? this.formatDateDDMMYYYY(this.quotationDateFormat) : this.quotationDateFormat;
    },
    computedDateFormatted2: {
      get() {
        return this.profileById.date_format ? this.formatDateDDMMYYYY(this.order_date) : parseDateYYYYMMDD(this.order_date);
      },
      set(val) {
        if (val.length == 10) {
          this.order_date = this.profileById.date_format ? this.parseDateYYYYMMDD(val) : formatDateDDMMYYYY(val);
        }
      },
    },
    computedorderValideDateFormat: {
      get() {
        return this.profileById.date_format ? this.formatDateDDMMYYYY(this.order_validity) : parseDateYYYYMMDD(this.order_validity);
      },
      set(val) {
        if (val.length == 10) {
          this.order_validity = this.profileById.date_format ? this.parseDateYYYYMMDD(val) : formatDateDDMMYYYY(val);
        }
      },
    },
    discountValid() {
      return [
        (v) => !v || /^\d{0,8}(\.\d{1,4})?$/.test(v) || this.$t("formRules.spaceValidValue"),
        (v) => !v || (v && String(v).length <= 12) || this.$t("formRules.lengthValidMessage", {
          fieldName: this.$t("quotation.field.discount"),
          type: this.$t("common.less"),
          size: 12,
        }),
        (v) => !v || v <= 100.00 || this.$t("formRules.discountValueExceedsLimit"),
      ];
    },
    deliveryNoteNoRequired() {
      return [
        (v) => !!v || this.$t("formRules.deliveryNoteNoRequired"),
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.deliveryNoteNoInteger"),
        (v) => !v || (v.toString().length < 9) || this.$t("formRules.lengthValidMessage", {
          fieldName: this.$t("supplierDeliveryNote.deliveryNoteNo"),
          type: this.$t("common.less"),
          size: 8,
        }),
      ];
    },
    invoiceNoRequired() {
      return [
        (v) => !!v || this.$t("formRules.invoiceNoRequired"),
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.invoicenoInteger"),
        (v) => !v || (v.toString().length < 9) || this.$t("formRules.lengthValidMessage", {
          fieldName: this.$t("supplierInvoice.invoiceNo"),
          type: this.$t("common.less"),
          size: 8,
        }),
      ];
    },
    groupValue() {
      return [
        { id: 2, name: this.$t("route.supplierDeliveryNote"), slug: "SupplierDeliveryNote" },
        { id: 3, name: this.$t("route.supplierInvoice"), slug: "SupplierInvoice" },
      ];
    },
  },
  watch: {
    supplierLimit(newVal, oldVal) {
      if (newVal !== oldVal)
        this.$store.dispatch("supplier/GetSupplier", {page: 1,limit: this.supplierLimit,order_by: "name_1|asc"});
    },
    quotationDateFormat() {
      this.model.create_date = this.profileById.date_format ? this.formatDateDDMMYYYY(this.quotationDateFormat) : this.quotationDateFormat;
    },
    order_date: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.multipleData = [];
          this.selectedItems = [];
        }
      },
      deep: true,
    },
    order_validity: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.multipleData = [];
          this.selectedItems = [];
        }
      },
      deep: true,
    },
    profileById() {
      if (Object.keys(this.profileById).length > 0) {
        this.model.vat_percentage = this.profileById.vat_percentage;
      }
    },
    locale() {
      if (this.processedData) {
        this.processedData.forEach((element) => {
          if (element.type === "discount") {
            if (this.locale === "he") {
              element.item_description = "הנחת מסמך";
            } else {
              element.item_description = "Document Discount";
            }
          }
        });
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("profile/GetCompanyById");
    this.quotationDateFormat = new Date().toISOString().substr(0, 10);
  },
  methods: {
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    parseDateYYYYMMDD: parseDateYYYYMMDD,
    todayDate: todayDate,
    redirectOnEditSupplierPage() {
      if (this.selectedSupplier) {
        this.$router.push({path: "/supplier/SupplierAction/" + this.selectedSupplier.id});
      } else {
        return false;
      }
    },
    async onSupplierChange(supplier) {
      this.multipleData = [];
      this.selectedItems = [];
      if (supplier && typeof supplier === "object" && Object.keys(supplier).length > 0) {
        this.selectedSupplier = { id: supplier.id, name_1: supplier?.name_1 };
        this.includeVatCharge = supplier?.vat_charge;
        this.model.vat_percentage = supplier.vat_charge ? this.profileById.vat_percentage : null;
      }
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.supplierLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async groupValueChange(item) {
      this.multipleData = [];
      this.selectedItems = [];
      this.selectedWarehouse = null;
      this.model.invoice_no = null;
      this.model.delivery_note_no = null;
      if (this.selectedGroup) {
        if (item.name === this.$t("route.supplierDeliveryNote")) {
          this.model.purchaseOrders = false;
          this.model.supplierDelivery = false;
          this.disableDocs.purchaseOrders = true;
          this.disableDocs.supplierDelivery = false;
        } else if (item.name === this.$t("route.supplierInvoice")) {
          this.model.supplierDelivery = false;
          this.model.purchaseOrders = false;
          this.disableDocs.purchaseOrders = true;
          this.disableDocs.supplierDelivery = true;
        }
      } else {
        this.model.purchaseOrders = false;
        this.model.supplierDelivery = false;
        this.disableDocs.purchaseOrders = false;
        this.disableDocs.supplierDelivery = false;
      }
    },
    async redirectDocument(item) {
      let routePath = "";
      switch (item.type) {
        case "purchaseOrder":
          routePath = "purchaseOrder/purchaseOrderAction/" + item.document_id;
          break;
        case "supplierDeliveryNote":
          routePath = "supplierDeliveryNote/supplierDeliveryNoteAction/" + item.document_id;
          break;
      }
      if (routePath) {
        window.open(routePath, "_blank");
      }
    },
    async execute() {
      if (this.$refs.form.validate()) {
        this.multipleData = [];
        this.circiular = true;
        if (this.model.purchaseOrders) {
          await this.$store.dispatch("multiple/GetPurchaseOrderItems", {
            supplier_id: this.selectedSupplier.id,
            from_date: this.order_date,
            to_date: this.order_validity,
            including_vat: this.model.vatNew,
          }).then((res) => {
            this.multipleData = this.multipleData.concat(res.data);
          });
        }
        if (this.model.supplierDelivery) {
          await this.$store.dispatch("multiple/GetSupplierDeliveryNoteItems", {
            supplier_id: this.selectedSupplier.id,
            from_date: this.order_date,
            to_date: this.order_validity,
            including_vat: this.model.vatNew,
          }).then((res) => {
            this.multipleData = this.multipleData.concat(res.data);
          });
        }
        this.multipleData.forEach((ele) => delete ele.supplied);
        this.multipleData.forEach((element) => {
          element.total = element.total.toFixed(2);
        });
        this.multipleData = this.generateDiscountRow(this.multipleData);
        this.circiular = false;
      }
    },
    toggleTypeSelection(typeRow) {
      const selected = this.typeSelection[typeRow.documentId];
      for (const item of this.multipleData) {
        if (item.document_id === typeRow.documentId && item.type !== "discount") {
          item.selected = selected;
        }
      }
      this.updateSelectedItems();
      this.addDiscountRowByToggle(typeRow);
    },
    isIndeterminate(typeRow) {
      const selectedTypeCount = this.multipleData.filter((item) => item.document_id === typeRow.documentId && item.selected).length;
      return (
        selectedTypeCount > 0 && selectedTypeCount < this.multipleData.filter(
          (item) => item.document_id === typeRow.documentId
        ).length
      );
    },
    generateDiscountRow(data) {
      const finalData = [];
      let count = 0;
      const arrayLastItem = data[data.length - 1];
      data.forEach((item, index, array) => {
        let lastItem = array[index - 1];
        if (lastItem && lastItem.discount_percentage) {
          if (lastItem.document_id === item.document_id) {
            finalData.push({...item,calculated_discount: this.calculateDocDiscount(item),supplied_discount: ""});
            count += 1;
          } else {
            finalData.push({
              id: lastItem.id,
              type: "discount",
              document_id: lastItem.document_id,
              document_number: lastItem.document_id,
              item_code: null,
              item_description: this.$t("common.documentDiscount"),
              quantity: 1,
              unit_price: count > 1 ? 0.0 : this.calculateDocDiscount(lastItem),
              total: count > 1 ? 0.0 : this.calculateDocDiscount(lastItem),
              oneUnitPrice: lastItem.oneUnitPrice,
            });
            count = 0;
            finalData.push({...item,calculated_discount: this.calculateDocDiscount(item),supplied_discount: ""});
            count += 1;
          }
        } else {
          if (item.discount_percentage) {
            finalData.push({...item,calculated_discount: this.calculateDocDiscount(item),supplied_discount: ""});
            count += 1;
          } else {
            finalData.push({...item,supplied_discount: "",calculated_discount: ""});
          }
        }
      });
      if (arrayLastItem && arrayLastItem.discount_percentage) {
        finalData.push({
          id: arrayLastItem.id,
          type: "discount",
          document_id: arrayLastItem.document_id,
          document_number: arrayLastItem.document_id,
          item_code: null,
          item_description: this.$t("common.documentDiscount"),
          quantity: 1,
          unit_price: arrayLastItem.document_id === data[data.length - 2]?.document_id ? 0.0 : this.calculateDocDiscount(arrayLastItem),
          total: arrayLastItem.document_id === data[data.length - 2]?.document_id ? 0.0 : this.calculateDocDiscount(arrayLastItem),
          oneUnitPrice: arrayLastItem.oneUnitPrice,
        });
      }
      return finalData;
    },
    async selectAllItem() {
      const allItemsSelected = this.multipleData.filter((el) => el.type !== "discount").every((item) => item.selected);
      for (const item of this.multipleData) {
        if (item.type !== "discount") {
          this.$set(item, "selected", !allItemsSelected);
        }
      }
      this.selectAll = !allItemsSelected;
      this.updateSelectedItems();
      await this.addDiscountRowBySelectAll();
    },
    async selectedProduct(item, checked) {
      const index = this.payloadData.findIndex((element) => {
        return element.id === item.id;
      });
      if (index === -1) {
        this.payloadData.push(item);
      } else {
        this.payloadData.splice(index, 1);
      }
      this.updateSelectedItems();
      await this.addDiscountRow(item, checked);
    },
    async addDiscountRowBySelectAll() {
      const filterTypeRows = this.processedData.filter((el) => el.typeRow);
      filterTypeRows.forEach((item) => {
        this.addDiscountRowByToggle(item);
      });
    },
    addDiscountRowByToggle(typeRow) {
      const groupItems = this.multipleData.filter((el) => el.document_id === typeRow.documentId);
      const findDiscountItem = groupItems.find((el) => el.type === "discount");
      const hasDiscountPercent = groupItems.some((el) => el.discount_percentage);
      if (hasDiscountPercent && groupItems.length > 1) {
        const filterSelected = groupItems.filter((el) => el.selected);
        filterSelected.forEach((el) => {
          let itemIndex = this.multipleData.findIndex((row) => row.id === el.id);
          this.multipleData[itemIndex].supplied_discount =
            el.calculated_discount * el.supplied;
        });
        const discountRowIndex = this.multipleData.findIndex(
          (row) => row.id === findDiscountItem.id && row.type === "discount"
        );
        const sum = filterSelected.reduce(
          (accumulator, currentValue) => accumulator + currentValue.calculated_discount * currentValue.supplied, 0
        );
        this.multipleData[discountRowIndex].unit_price = sum;
        this.multipleData[discountRowIndex].total = sum;
      }
    },
    async addDiscountRow(item) {
      if (item.discount_percentage) {
        const groupItems = this.multipleData.filter((el) => el.document_id === item.document_id);
        const findDiscountItem = await groupItems.find((el) => el.type === "discount");
        const clickedItemIndex = this.multipleData.findIndex((row) => row.id === item.id);
        if (groupItems.length > 1) {
          const filterSelected = groupItems.filter((el) => el.selected);
          const discountRowIndex = this.multipleData.findIndex(
            (row) => row.id === findDiscountItem.id && row.type === "discount"
          );
          const sum = filterSelected.reduce(
            (accumulator, currentValue) => accumulator + currentValue.calculated_discount * currentValue.supplied, 0
          );
          this.multipleData[discountRowIndex].unit_price = sum;
          this.multipleData[discountRowIndex].total = sum;
          this.multipleData[clickedItemIndex].supplied_discount = item.calculated_discount * item.supplied;
        }
      }
    },
    calculateDocDiscount(item) {
      const total_1 = item.total / item.quantity;
      const total_2 = total_1 - total_1 * (item.discount_percentage / 100);
      const final_unit_price = total_2 * (1 * -1);
      return final_unit_price;
    },
    addDiscountData(item) {
      const calculatedPrice = this.calculateDocDiscount(item);
      return {
        id: item.id,
        type: "discount",
        document_id: item.document_id,
        document_number: item.document_id,
        item_code: null,
        item_description: this.$t("common.documentDiscount"),
        quantity: 1,
        unit_price: calculatedPrice,
        total: calculatedPrice,
        oneUnitPrice: item.oneUnitPrice,
      };
    },
    updateSelectedItems() {
      this.selectedItems = this.multipleData.filter((item) => item.selected);
      const multipleDataWithoutDis = this.multipleData.filter((el) => el.type !== "discount");
      if (this.selectedItems.length === multipleDataWithoutDis.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
      this.handleSupplyStatus();
      this.priceCalculation();
    },
    handleSupplyStatus() {
      for (const item of this.multipleData) {
        item.disabled = item.selected ? true : false;
      }
    },
    numberFromCommas(x) {
      return x ? x.toString().replace(/,/g, "") : null;
    },
    numberWithCommas(x, type = "float") {
      return x ? type === "float" ? parseFloat(x).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : type === "float" ? (0).toFixed(2) : x;
    },
    priceCalculation() {
      let self = this;
      if (this.model.vatNew) {
        this.model.total =
          this.selectedItems.length > 0
            ? this.selectedItems
                .reduce(function (total, item) {
                  return parseFloat(self.numberFromCommas(item.total)) ? parseFloat(total) + parseFloat(self.numberFromCommas(item.total)) : total + 0;
                }, 0)
                .toFixed(2) /
              (self.model.vat_percentage / 100 + 1)
            : 0;
      } else {
        this.model.total =
        this.selectedItems.length > 0
          ? this.selectedItems
              .reduce(function (total, item) {
                return parseFloat(self.numberFromCommas(item.total)) ? parseFloat(total) + parseFloat(self.numberFromCommas(item.total)) : total + 0;
              }, 0)
              .toFixed(2)
          : 0;
      }

      this.model.total = parseFloat(this.model.total) - this.selectedItems?.reduce((accumulator, currentValue) =>
        accumulator - (currentValue.calculated_discount * Number(currentValue.supplied)), 0
      );

      if (!this.model.grand_total_requestd) {
        this.model.discount_percentage = parseFloat(this.model.discount_percentage) ? parseFloat(this.model.discount_percentage).toFixed(2) : null;
        this.model.discount = parseFloat(this.model.discount_percentage) ? ((parseFloat(this.model.total) * parseFloat(this.model.discount_percentage)) / 100).toFixed(2) : (0).toFixed(2);
      }
      this.model.after_discount = parseFloat(this.model.discount) ? (parseFloat(this.model.total) - parseFloat(this.model.discount)).toFixed(2) : parseFloat(this.model.total).toFixed(2);

      this.model.vat_percentage = parseFloat(this.model.vat_percentage) ? parseFloat(this.model.vat_percentage) : null;
      this.model.vat = parseFloat(this.model.vat_percentage) ? ((parseFloat(this.model.after_discount) * parseFloat(this.model.vat_percentage)) / 100).toFixed(2) : (0).toFixed(2);

      this.model.grand_total = parseFloat(this.model.vat) ? (parseFloat(this.model.after_discount) + parseFloat(this.model.vat)).toFixed(2) : parseFloat(this.model.after_discount).toFixed(2);

      if (this.profileById.total_rounding) {
        let total = this.model.total;
        let discount = this.model.discount;
        let vatPercentage = this.model.vat_percentage;
        let grandTotal = parseInt(this.model.grand_total);
        let roundingDiff = this.model.grand_total - grandTotal;
        let findRoundNumber = parseFloat(roundingDiff / (1 + vatPercentage / 100)).toFixed(2);
        if (findRoundNumber <= 0.5) {
          // calculate discount if findRoundNumber is lessthan 0.5
          this.model.discount = (parseFloat(discount) + parseFloat(findRoundNumber)).toFixed(2);
          // calculate after_discount
          this.model.after_discount = (parseFloat(total) - parseFloat(this.model.discount)).toFixed(2);
          // calculate vat
          this.model.vat = (parseFloat(grandTotal) - parseFloat(this.model.after_discount)).toFixed(2);
          // calculate grand_total
          this.model.grand_total = parseFloat(grandTotal).toFixed(2);
        } else {
          let roundingDiff = parseFloat((grandTotal + 1 - this.model.grand_total) * -1).toFixed(2);
          // calculate discount if findRoundNumber is lessthan 0.5
          this.model.discount = (parseFloat(discount) + parseFloat(roundingDiff / (1 + vatPercentage / 100))).toFixed(2);
          // calculate after_discount
          this.model.after_discount = (parseFloat(total) - parseFloat(this.model.discount)).toFixed(2);
          // calculate vat
          this.model.vat = (parseFloat(grandTotal) + 1 - parseFloat(this.model.after_discount)).toFixed(2);
          // calculate grand_total
          this.model.grand_total = (parseFloat(grandTotal) + 1).toFixed(2);
        }
      }
      this.$forceUpdate();
    },
    itemPriceCalculation(index) {
      let item = this.processedData[index];

      // unit_price calculation
      let checkUnitPrice = item?.unit_price;
      if (checkUnitPrice && this.$refs["unit_price" + index]) {
        const pos = this.$refs["unit_price" + index][0].$el.querySelector("input").selectionStart + 1;
        if (checkUnitPrice.length != this.numberWithCommas(this.numberFromCommas(item.unit_price),"noFloat").length) {
          this.$nextTick(() => {
            this.$refs["unit_price" + index][0]?.$el ? (this.$refs["unit_price" + index][0].$el.querySelector("input").selectionEnd = pos) : "";
          });
        } else {
          this.$nextTick(() => {
            this.$refs["unit_price" + index][0]?.$el ? (this.$refs["unit_price" + index][0].$el.querySelector("input").selectionEnd = pos - 1) : "";
          });
        }
      }

      // total calculation
      let checkTotal = item?.total;
      if (checkTotal && this.$refs["total_price" + index]) {
        const pos = this.$refs["total_price" + index][0].$el.querySelector("input").selectionStart + 1;
        if (checkTotal.length != this.numberWithCommas(this.numberFromCommas(item.total), "noFloat").length) {
          this.$nextTick(() => {
            this.$refs["total_price" + index][0]?.$el ? (this.$refs["total_price" + index][0].$el.querySelector("input").selectionEnd = pos) : "";
          });
        } else {
          this.$nextTick(() => {
            this.$refs["total_price" + index][0]?.$el ? (this.$refs["total_price" + index][0].$el.querySelector("input").selectionEnd = pos - 1) : "";
          });
        }
      }

      if (this.processedData && Array.isArray(this.processedData) && this.processedData[index]) {
        this.processedData[index].unit_price = item.unit_price ? this.numberWithCommas(this.numberFromCommas(item.unit_price), "noFloat") : null;
        this.processedData[index].total = item.total ? this.numberWithCommas(this.numberFromCommas(item.total), "noFloat") : null;
      }

      this.$nextTick(() => {
        if (item && typeof item === "object") {
          if (this.$refs.form && this.$refs["unit_price" + index] && this.$refs["unit_price" + index][0].validate()) {
            item.unit_price = item.unit_price ? this.numberFromCommas(item.unit_price) : null;
          }

          if (this.$refs.form && this.$refs["total_price" + index] && this.$refs["total_price" + index][0].validate()) {
            item.total = this.numberFromCommas(item.total);
          }
        }
        if (item && typeof item === "object" && "unit_price" in item) {
          if (parseFloat(item.unit_price)) {
            if (!item.typeRow) {
              item.total =
                parseFloat(item.supplied) && parseFloat(item.unit_price)
                  ? (parseFloat(item.supplied) * parseFloat(item.unit_price)).toFixed(2)
                  : parseFloat(item.unit_price)
                  ? parseFloat(item.unit_price).toFixed(2)
                  : (0).toFixed(2);

              // item total calculation with discount
              item.total = parseFloat(item.line_discount)
                ? (parseFloat(item.total) - (parseFloat(item.total) * parseFloat(item.line_discount)) / 100).toFixed(2)
                : parseFloat(item.total).toFixed(2);

              this.processedData[index].unit_price = this.numberWithCommas(this.numberFromCommas(item.unit_price),"noFloat");
              this.processedData[index].total = this.numberWithCommas(this.numberFromCommas(item.total),"noFloat");
            }
          } else {
            this.processedData[index].total = this.numberWithCommas(this.numberFromCommas(item.total),"noFloat");
          }
        }
        if (item.selected) {
          this.addDiscountRow(item);
        }
        this.processedData[index] = item;
        this.priceCalculation();
      });
    },
    async saveMultiple() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        await this.$store.dispatch("supplier/GetSupplierById", this.selectedSupplier.id).then((res) => {
          this.supplierData = res;
        });
        this.selectedItems = this.selectedItems.map((item) => ({
          ...item,
          quantity: parseInt(item.supplied, 10),
          supplied: Number(item.supplied),
          doc_items_id: item.id,
          multiple_doc_type: item.type,
          unit_price: item.unit_price ? parseFloat(this.numberFromCommas(item.unit_price)).toFixed(2) : "",
          total: item.total ? parseFloat(this.numberFromCommas(item.total)).toFixed(2) : "",
          line_discount: item.line_discount ? parseFloat(item.line_discount).toFixed(2) : "",
          same_doc_id: item.order_id ? item.order_id 
            : item.delivery_note_id ? item.delivery_note_id
            : "",
          source_document_number: item.document_number,
          source_document_customer_order_no: item.customer_order_no,
          source_document_date: item.document_date,
          source_document_title: item.title,
        }));
        const payloadItems = JSON.parse(JSON.stringify(this.selectedItems))
        const keysToDelete = ["selected","oneUnitPrice","document_id","order_id","quotation_id","id","type","customer_order_no","document_date","title","disabled","discount_percentage","document_number","delivery_note_id"];
        payloadItems.forEach((v) => {
          keysToDelete.forEach((key) => delete v[key]);
        });
        const payload = {
          data: payloadItems,
          supplier_name: this.supplierData.name_1,
          address: this.supplierData.address_1,
          town: this.supplierData.town_1,
          country: this.supplierData.country,
          email: this.supplierData.email,
          phone_1: this.supplierData.phone_1,
          phone_2: this.supplierData.phone_2,
          fax: this.supplierData.fax,
          cellular: this.supplierData.cellular_1,
          authorized_dealer_no: this.supplierData.authorized_dealer_no,
          supplier_id: this.supplierData.id,
          vat_percentage: this.model.vat_percentage,
          grand_total: this.model.grand_total ? this.model.grand_total : null,
          total: this.model.total ? this.model.total : null,
          discount: this.model.discount ? this.model.discount : 0,
          discount_percentage: this.model.discount_percentage ? this.model.discount_percentage : "",
          vat: this.model.vat ? this.model.vat : null,
          after_discount: this.model.after_discount ? this.model.after_discount : null,
          including_vat: this.model.vatNew ? 1 : 0,
          year: Number(this.quotationDateFormat.split("-")[0]),
          warehouse: (this.profileById.warehouse_inventory_management && this.selectedWarehouse) ? this.selectedWarehouse.id : null
        };
        if (this.selectedGroup.id === 2) {
          this.circiular = true;
          const data = await this.$store.dispatch("supplierDeliveryNote/SetSupplierDeliveryNote", {
            ...payload,
            delivery_date: this.quotationDateFormat,
            main_number: 94,
            status: 1,
            related_number: 16,
            delivery_note_no: this.model.delivery_note_no
          }).catch((e) => {
            this.deliveryNoteNoDialog = true;
            this.loading = false;
            console.log(e);
          })
          if (data) {
            if (this.accessRight.includes("edit")) {
              this.$router.push("supplierDeliveryNote/supplierDeliveryNoteAction/" + data.data.id);
            } 
          }
          this.circiular = false;
        } else if (this.selectedGroup.id === 3) {
          this.circiular = true;
          const data = await this.$store.dispatch("supplierInvoice/SetSupplierInvoice", {
            ...payload,
            balance: this.model.grand_total ? this.model.grand_total : null,
            invoice_date: this.quotationDateFormat,
            main_number: 21,
            status: 1,
            invoice_no: this.model.invoice_no 
          }).catch((e) => {
            this.deliveryNoteNoDialog = true;
            this.loading = false;
            console.log(e);
          })
          if (data) {
            if (this.accessRight.includes("edit")) {
              this.$router.push("supplierInvoice/supplierInvoiceAction/" + data.data.id);
            } 
          }
          this.circiular = false;
        }
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
</style>
